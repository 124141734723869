<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item>课程签到</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 表格数据 -->
    <el-card class="righttable">
      <i class="el-icon-tickets"></i>
      <span>我的课程</span>
      <el-table
        :data="signData"
        border
        stripe
        style="width: 100%; height: auto"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column
          label="序号"
          type="index"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" label="课程名称" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="week" label="周数" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.week }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="section" label="课程时间" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.section }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="acttype" label="签到名单" align="center">
          <template slot-scope="scope">
            <p>
              {{
                scope.row.acttype == 1
                  ? '课堂签到'
                  : scope.row.acttype == 2
                  ? '聚会签到'
                  : scope.row.acttype == 3
                  ? '会议签到'
                  : scope.row.acttype == 4
                  ? '讲座签到'
                  : '活动签到'
              }}
            </p>
          </template>
        </el-table-column> -->
        <el-table-column prop="signStopType" label="签到方式" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.signStopType == 1 ? '手动控制签到' : '倒计时签到' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          sortable
          label="创建时间"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.createtime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="300" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleShow(scope.$index, scope.row)"
              >查看签到活动</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import qs from 'qs'
import Cookies from 'js-cookie'
const defaultFormInline = {
  pageNum: 1,
  pageSize: 5,
  university: null,
  academy: null,
  name: null
}
export default {
  name: 'signList',
  data() {
    return {
      signlist: [],
      signData: [],
      formInline: {
        university: '',
        academy: '',
        name: '',
        pageNum: 1,
        pageSize: 5
      },
      tid: null, //用户id
      uid: null, //用户id
      // 分页
      total: 0,
      searchCode: '', // 用户输入的组织码
      organizationSearch: [], //查找的组织
      ccid: '' //课程id
    }
  },
  created() {
    var toCode = window.sessionStorage.getItem('toCode')
    if (toCode == 'true') {
      this.$confirm('是否跳转到当前签到活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          window.sessionStorage.setItem('toCode', false)
          var lid = window.sessionStorage.getItem('lid')
          var endTime = window.sessionStorage.getItem('loginEndTime')
          var state = window.sessionStorage.getItem('loginState')
          this.$router.push({
            // path: '/signList/signlinkList/signCode',
            path: '/mycourse/signlinkList/signCode',
            query: { lid: lid, EndTime: endTime, status: state }
          })
        })
        .catch(() => {})
    }

    this.tid = window.sessionStorage.getItem('uid')
    this.uid = window.sessionStorage.getItem('uid')
    // this.tid = this.$route.query.tid
    window.sessionStorage.setItem('tid', this.tid)

    this.selectList()
  },
  mounted: function() {},
  methods: {
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val

      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.selectList()
    },

    // 查询签到环节
    handleShow(index, row) {
      window.sessionStorage.setItem('ccid', row.ccid) //课程id
      window.sessionStorage.setItem('title', row.name) //课程的名字
      this.$router.push({
        path: '/mycourse/signList'
      })
    },
    // 查询签到活动
    async selectList() {
      const { data: res } = await this.$http.get('/cou/findCouByuid', {
        params: {
          uid: this.uid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.signData = res.data
        this.total = res.total
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// body {
//   padding-right: 0px !important;
//   overflow: hidden;
// }
.app-container {
  width: 100%;
  // height: 100vh;
  // padding-bottom: 300px;
  // overflow: auto !important;
}
.input-width {
  width: 203px;
}
.home-breadcrumb {
  position: relative;
  top: -52px;
  left: 29px;
}
.el-dialog {
  box-sizing: border-box;
}
.el-dialog__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.el-dialog__header {
  margin: 0;
  padding: 0;
}
.search-container {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  .search {
    color: #409eff;
    font-size: 25px;
    font-weight: bold;
    margin-right: 5px;
    align-items: center;
  }
  .el-button {
    font-size: 16px;
  }
}
</style>
